body {
  margin: 0;
}

div,
p {
  white-space: pre-line;
}

.app-logo {
  height: 8vmin;
  pointer-events: none;
}

img.ant-image-preview-img {
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.site-layout-sub-header-background {
  background: #fff;
  border-bottom: '1px solid #f7f7f7';
  padding: 0 10px;
}

.site-layout-background {
  background: #fff;
}

.site-page-header {
  padding: 0;
}

.element-to-print {
  display: none;
}

.semibold {
  font-weight: 500;
}

/* Workaround to use antd Card style when printing invoices */
@media print {
  .ant-card.invoices {
    border: none;
    height: 100vh;
  }
  .ant-card.invoices > .ant-card-head {
    display: none;
  }
  .element-to-print {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    font-family: 'Inter', sans-serif;
    font-weight: 500; /* Slightly bolder than normal */
    b,
    strong {
      font-weight: bold; /* Ensures existing bold text remains bold */
    }
  }
  .element-to-print > .page {
    border: none;
    height: 100vh;
  }
  .element-to-print > .separator {
    page-break-after: always;
  }
  .element-to-print > .ant-card.page > .ant-card-body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1rem;
  }
}
